
enum API {
  //Auth
  LOGIN = "/auth/login",
  SIGNUP = "/auth/signup",
  LOGOUT = "/auth/logout",
  CREATE_PASSWORD = "/pm-hermes/auth/set-password",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  VALIDATE_RESET_PASSWORD = "/auth/reset-password/validate",
  REFRESH_TOKEN = "auth/refresh",
  PROFILE = "/user/profile",

  //Role & Permissions
  GET_ROLES = "process-pilot-provisioning-service/api/roles",
  GET_PERMISSION_DETAILS = "process-pilot-provisioning-service/api/roles/details",
  GET_USER = "process-pilot-provisioning-service/api/users",
  INVITE_USER = "process-pilot-provisioning-service/api/users/invite",
  GET_PERMISSION_LIST = "process-pilot-provisioning-service/api/permissions",
  INITIAL_LOGIN = "process-pilot-provisioning-service/api/users/me",
  // ROLES = "/pm-hermes/health",
  
  //Task
  GET_FIELDS = "process-pilot-provisioning-service/field-types",
  TASK_ACTIONS = "process-pilot-provisioning-service/api/tasks",
  UPLOAD_DOC = "process-pilot-provisioning-service/api/tasks",
  MY_TASKS = "process-pilot-provisioning-service/api/users/me/tasks"
}

const publicEndpoints = [
  API.FORGOT_PASSWORD,
  API.LOGIN,
  API.RESET_PASSWORD,
  API.LOGOUT,
  // API.REFRESH_TOKEN,
  API.RESET_PASSWORD,
  API.SIGNUP,
  API.VALIDATE_RESET_PASSWORD,
];

export { publicEndpoints };

export default API;
