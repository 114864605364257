import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MainRoute from "./routes";
import { useEffect, useState } from "react";
import { checkAuthorization } from "./store/slices/user";
import { useAppDispatch } from "./store/hooks";
import { DragDropContext } from "react-beautiful-dnd";
import { useOnDragEnd } from "./hooks/useOnDragEnd";
import Values from "values.js";

const App = () => {
  const dispatch = useAppDispatch();
  const onDragEnd = useOnDragEnd();
  const [colors, setColors] = useState<any>([]);
  useEffect(() => {
    try {
      const shades = new Values(localStorage.getItem("color") || "#025331").all(
        14,
      );
      setColors(shades);
    } catch (err) {
      console.log(err);
    }
  }, [localStorage.getItem("color")]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-5",
      colors[1]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-10",
      colors[2]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-20",
      colors[3]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-30",
      colors[4]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-40",
      colors[5]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-50",
      colors[6]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-base",
      colors[7]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-60",
      colors[8]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-70",
      colors[9]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-80",
      colors[10]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-90",
      colors[11]?.rgb.join(","),
    );
    document.documentElement.style.setProperty(
      "--primary-100",
      colors[12]?.rgb.join(","),
    );
  }, [colors]);

  useEffect(() => {
    dispatch(checkAuthorization());
  }, [dispatch]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <MainRoute />
        <ToastContainer
          closeOnClick
          style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
          position={toast.POSITION.BOTTOM_RIGHT}
          bodyStyle={{ color: "#756f86" }}
        />
      </DragDropContext>
    </>
  );
};

export default App;
