import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TaskService from "@/services/task.service";

export interface TaskState {
    tasks:[] | null,
    loading:boolean,
}

export const initialState: TaskState = {
    tasks:null,
    loading:false
}

export const fetchTasks = createAsyncThunk("tasks/fetch",async(_,{ rejectWithValue, dispatch })=>{
try{
    const response:any = await TaskService.getTasks();
    return response;
}catch(error:any){
    throw new Error(error);
}
})

const taskSlice = createSlice({
    name: "tasks",
    initialState: {
        tasks: [],
        loading:false,
    },
    reducers:  {
        clearError(state){
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(fetchTasks.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchTasks.fulfilled, (state, action) => {
            state.loading=false;
            state.tasks = action.payload;
        })
    }
})

export default taskSlice.reducer;